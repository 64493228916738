export default [
  {
    path: '/blog/aprende/cultura-general/para-que-sirve-el-liderazgo',
    name: '¿Para qué sirve el liderazgo?; Aquí su importancia y beneficios',
    meta: {
      title: "¿Para qué sirve el liderazgo?; Aquí su importancia y beneficios",
      keywords: "¿Para qué sirve el liderazgo?, ¿Para qué sirve el liderazgo?, término, concepto, características, tipos de liderazgo, cualidades de un líder",
      metaTags:
      {
        "og:keywords": "¿Para qué sirve el liderazgo?, ¿Para qué sirve el liderazgo?, término, concepto, características, tipos de liderazgo, cualidades de un líder"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0078-ART-APRENDE-CULTURA-GENERAL-270924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/porque-es-importante-la-inteligencia-emocional',
    name: '¿Por qué es importante la inteligencia emocional?',
    meta: {
      title: "¿Por qué es importante la inteligencia emocional?",
      keywords: "¿Por qué es importante la inteligencia emocional?,Inteligencia emocional: definición, significado, beneficios y ventajas. Inteligencia emocional: en el trabajo, en la escuela y en la familia",
      metaTags:
      {
        "og:keywords": "¿Por qué es importante la inteligencia emocional?,Inteligencia emocional: definición, significado, beneficios y ventajas. Inteligencia emocional: en el trabajo, en la escuela y en la familia"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0077-ART-APRENDE-CULTURA-GENERAL-240924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/nietzsche-para-generacion-z-filosofia',
    name: 'Nietzsche para la Generación Z: Filosofía para cuestionar todo lo que sabes',
    meta: {
      title: "Nietzsche para la Generación Z: Filosofía para cuestionar todo lo que sabes",
      keywords: "quien es nietzsche, nietzsche para jóvenes, corriente filosofica de nietzsche, nihilismo, nietzsche pensamiento, nietzsche filosofia, generación z",
      metaTags:
      {
        "og:keywords": "quien es nietzsche, nietzsche para jóvenes, corriente filosofica de nietzsche, nihilismo, nietzsche pensamiento, nietzsche filosofia, generación z"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0075-ART-APRENDE-CULTURA-GENERAL-190924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/peliculas-de-abogados',
    name: 'Películas para estudiantes y abogados imperdibles',
    meta: {
      title: "Películas para estudiantes y abogados imperdibles",
      keywords: "Películas de abogados, Películas de abogados en Netflix, series de abogados en Netflix, películas de Derecho",
      metaTags:
      {
        "og:keywords": "Películas de abogados, Películas de abogados en Netflix, series de abogados en Netflix, películas de Derecho"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0074-ART-APRENDE-CULTURA-GENERAL-180924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/en-que-paises-se-habla-el-idioma-ingles',
    name: '¿En qué países se habla el idioma inglés? Guía completa, entérate',
    meta: {
      title: "¿En qué países se habla el idioma inglés? Guía completa, entérate",
      keywords: "en qué países se habla el idioma inglés, paises que hablan ingles, idioma inglés, qué idioma hablan en Inglaterra, 10 países que hablan inglés, donde se habla inglés, donde se habla ingles",
      metaTags:
      {
        "og:keywords": "en qué países se habla el idioma inglés, paises que hablan ingles, idioma inglés, qué idioma hablan en Inglaterra, 10 países que hablan inglés, donde se habla inglés, donde se habla ingles"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0056-ART-APRENDE-CULTURA-GENERAL-070724.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024',
    name: 'Día Mundial de las Redes Sociales: Celebrando el 30 de junio',
    meta: {
      title: "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
      description: "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
      keywords: "qué se celebra el 30 de junio, día mundial de las redes sociales, 30 de junio día internacional de las redes sociales",
      metaTags:
      {
        "og:title": "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
        "og:description": "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
        "og:keywords": "qué se celebra el 30 de junio, día mundial de las redes sociales, 30 de junio día internacional de las redes sociales"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0051-ART-APRENDE-CULTURA-GENERAL-270624.vue')
  },
  {
    path: '/blog/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex',
    name: '¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?',
    meta: {
      title: "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
      description: "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
      keywords: "Cuántos pisos tiene la torre de Pemex, edificio más alto de México, edificio más alto de cdmx, torre pemex: Roberto Ramírez Guevara, torre más alta de méxico, edificios cdmx",
      metaTags:
      {
        "og:title": "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
        "og:description": "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
        "og:keywords": "Cuántos pisos tiene la torre de Pemex, edificio más alto de México, edificio más alto de cdmx, torre pemex: Roberto Ramírez Guevara, torre más alta de méxico, edificios cdmx"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0044-ART-APRENDE-CULTURA-GENERAL-130624.vue')
  },
  {
    path: '/blog/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales',
    name: 'Uso responsable de las redes sociales: Guía para navegar de forma segura',
    meta: {
      title: "Uso responsable de las redes sociales: Guía para navegar de forma segura",
      description: "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
      keywords: "Uso responsable de las redes sociales, Recomendaciones para el uso de las redes sociales, uso adecuado de las redes sociales, uso de las redes sociales, buen uso de las redes sociales, como usar correctamente las redes sociales",
      metaTags:
      {
        "og:title": "Uso responsable de las redes sociales: Guía para navegar de forma segura",
        "og:description": "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
        "og:keywords": "Uso responsable de las redes sociales, Recomendaciones para el uso de las redes sociales, uso adecuado de las redes sociales, uso de las redes sociales, buen uso de las redes sociales, como usar correctamente las redes sociales"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0029-ART-APRENDE-CULTURA-GENERAL-210524.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dia-del-maestro-2024',
    name: 'Celebrando el Día del Maestro: reconozcamos a nuestros profesores',
    meta: {
      title: "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
      description: "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
      keywords: "",
      metaTags:
      {
        "og:title": "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
        "og:description": "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
        "og:keywords": ""
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0025-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024',
    name: 'Día de la Mujer 2024: invertir para tener igualdad de género',
    meta: {
      title: "Día de la Mujer 2024: invertir para tener igualdad de género",
      description: "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
      keywords: "día mundial de la mujer, día internacional de la mujer, 8 de marzo, igualdad de género, financiar, invertir, acelerar",
      metaTags:
      {
        "og:title": "Día de la Mujer 2024: invertir para tener igualdad de género",
        "og:description": "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
        "og:keywords": "día mundial de la mujer, día internacional de la mujer, 8 de marzo, igualdad de género, financiar, invertir, acelerar"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/11-ART-APRENDE-CULTURA-GENERAL-080324.vue')
  },
]