export default [
    {
      path: '/blog/academico/maestria/tipos-de-maestrias-en-mercadotecnia',
      meta: {
        title: "¿Qué tipos de Maestrías en Mercadotecnia hay? ¡Conócelas!",
        keywords: "mercadotecnia digital, requisitos, eventos, egresados, tipos de maestrías en mercadotecnia",
        metaTags:
        {
          "og:keywords": "mercadotecnia digital, requisitos, eventos, egresados, tipos de maestrías en mercadotecnia",
        },
        requireAuth: false,
      },
      name: '¿Qué tipos de Maestrías en Mercadotecnia hay? ¡Conócelas!',
      component: () => import('../../pages/Blog/Academico/Maestria/0080-ART-ACADEMICO-MAESTRIA-031024.vue')
    },
    {
      path: '/blog/academico/maestria/derecho-corporativo-empresarial',
      meta: {
        title: "Derecho corporativo empresarial: aspectos fundamentales",
        description: "El Derecho Corporativo Empresarial es una área jurídica especializada que conjuga diversas áreas de conocimiento, conócelas.",
        image: "https://blog.humanitas.edu.mx/images/meta/derecho-corporativo-empresarial-mercantil.webp",
        keywords: "Derecho corporativo empresarial, características del derecho empresarial, derecho de la empresa, derecho corporativo",
        metaTags:
        {
          "og:title": "Derecho corporativo empresarial: aspectos fundamentales",
          "og:description": "El Derecho Corporativo Empresarial es una área jurídica especializada que conjuga diversas áreas de conocimiento, conócelas.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/derecho-corporativo-empresarial-mercantil.webp",
          "og:keywords": "Derecho corporativo empresarial, características del derecho empresarial, derecho de la empresa, derecho corporativo",
        },
        requireAuth: false,
      },
      name: 'Derecho corporativo empresarial: aspectos fundamentales',
      component: () => import('../../pages/Blog/Academico/Maestria/0031-ART-ACADEMICO-MAESTRIA-230524.vue')
    },
    {
        path: '/blog/academico/maestria/maestria-psicologia-guia-completa',
        meta: {
          title: "Maestría en Psicología: Guía completa",
          description: "Describimos todo lo que necesita saber de la Maestría en Psicología, cuyo principal objetivo es fortalecer habilidades y conocimientos en terrenos prácticos y teóricos.",
          image: "https://blog.humanitas.edu.mx/images/meta/M-maestria-psicologia-tipos-mente-humana.jpg",
          metaTags:
          {
            "og:title": "Maestría en Psicología: Guía completa",
            "og:description": "Describimos todo lo que necesita saber de la Maestría en Psicología, cuyo principal objetivo es fortalecer habilidades y conocimientos en terrenos prácticos y teóricos.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/M-maestria-psicologia-tipos-mente-humana.jpg",
          },
          requireAuth: false,
        },
        name: 'Maestría en Psicología: Guía completa',
        component: () => import('../../pages/Blog/Academico/Maestria/019-ART-ACADEMICO-MAESTRIA-260424.vue')
    },
    {
        path: '/blog/academico/maestria/costo-y-requisitos-estudiar-maestria',
        meta: {
          title: "Costo y requisitos para estudiar una maestría: entérate",
          description: "Eleva tus oportunidades laborales y alcanza tus metas profesionales. Te orientamos sobre el costo y los requisitos para estudiar una maestría",
          image: "https://blog.humanitas.edu.mx/images/meta/Costo-y-requisitos-estudiar-maestria.jpg",
          metaTags:
          {
            "og:title": "Costo y requisitos para estudiar una maestría: entérate",
            "og:description": "Eleva tus oportunidades laborales y alcanza tus metas profesionales. Te orientamos sobre el costo y los requisitos para estudiar una maestría",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Costo-y-requisitos-estudiar-maestria.jpg",
          },
          requireAuth: false,
        },
        name: 'Costo y requisitos para estudiar una maestría: entérate',
        component: () => import('../../pages/Blog/Academico/Maestria/02-ART-ACADEMICO-MAESTRIA-240124.vue')
    },
]