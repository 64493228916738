<template>
  <div @mouseover="cancelCloseTimer" @mouseleave="closeDropdown">
    <div @click="toggleSearch" class="search-icon">
      <img src="../pages/images/lupa.png" class="w-8">
    </div>
    <div v-if="showSearch" class="relative">
      <input type="text" v-model="searchQuery"  placeholder="Buscar..." @keyup="search" @keydown.esc="closeDropdown" ref="searchInput" style="width: 100%;padding: 10px 10px;font-size: 16px;color:black;margin-bottom: 8px;border: none;border-bottom: 1px solid #797979;outline: none;background: transparent;" class=" focus:ring-htwo">
      <div v-if="results.length > 0" class="absolute left-0 mt-0 lg:w-60 w-[52] italic rounded-none shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @mouseover="cancelCloseTimer" @mouseleave="closeDropdown">
        <router-link @click="cleanOptions"
          v-for="(item, index) in results"
          :key="index"
          :to="`/blog${item.Enlace}`"
          class="dropdown-item block px-4 text-left py-2 lg:text-sm text-[12px] text-gray-700 hover:bg-[#881912] hover:text-gray-900"
          role="menuitem"
        >
          {{ item.TituloArt }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import axios from 'axios';
import { ConsultasBlog } from '@/main';

export default {
  data() {
    return {
      showSearch: false,
      searchQuery: '',
      /**
       * @type {{ Enlace: string, TituloArt: string }[]}
       */
      results: [] // Para almacenar los resultados de la búsqueda
    };
  },
  methods: {
    cleanOptions() {
      this.searchQuery = '',
      this.results = []
      this.showSearch = !this.showSearch;
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs.searchInput.focus();
        });
      }
    },
    async search() {
      if(this.searchQuery.length>=4) {
        if (this.searchQuery.trim() === '') {
        this.results = [];
        return;
        }
        try {
          const response = await axios.get(ConsultasBlog, {
            params: {
              action: 'SearchArticlesByName',
              params: JSON.stringify([this.searchQuery])
            }
          });

          if (response.data.error) {
            console.error(response.data.error);
          } else {
            // @ts-ignore
            this.results = response.data.map(item => ({
              Enlace: item.Enlace || 'Enlace desconocido',
              TituloArt: item.TituloArt || 'Título desconocido'
            }));
          }
        } catch (error) {
          console.error('Error al buscar artículos:', error);
        }
      } else {
        this.results = []
      }
      
    },
    cancelCloseTimer() {
      // @ts-ignore
      clearTimeout(this.closeTimer);
    },
    closeDropdown() {
      // @ts-ignore
      this.closeTimer = setTimeout(() => {
        this.showSearch = false;
      }, 300);
      this.searchQuery = '',
      this.results = []
      
    }
  }
};
</script>

<style scoped>
.search-icon {
  cursor: pointer;
}
.dropdown-item {
  transition: background-color 0.2s ease;
}
</style>