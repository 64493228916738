export default [
    {
        path: '/blog/historico/2024/enero',
        name: 'Enero 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/1-enero2024.vue'),
        meta: {
            title: "Enero 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/febrero',
        name: 'Febrero 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/2-febrero2024.vue'),
        meta: {
            title: "Febrero 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/marzo',
        name: 'Marzo 2024 | Blog Humanitas',
        component: import('../../pages/Blog/Historico/2024/3-marzo2024.vue'),
        meta: {
            title: "Marzo 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/abril',
        name: 'Abril 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/4-abril2024.vue'),
        meta: {
            title: "Abril 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/mayo',
        name: 'Mayo 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/5-mayo2024.vue'),
        meta: {
            title: "Mayo 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/junio',
        name: 'Junio 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/6-junio2024.vue'),
        meta: {
            title: "Junio 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/julio',
        name: 'Julio 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/7-julio2024.vue'),
        meta: {
            title: "Julio 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/agosto',
        name: 'Agosto 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/8-agosto2024.vue'),
        meta: {
            title: "Agosto 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/septiembre',
        name: 'Septiembre 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/9-septiembre2024.vue'),
        meta: {
            title: "Septiembre 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/octubre',
        name: 'Octubre 2024 | Blog Humanitas',
        component: () => import('../../pages/Blog/Historico/2024/10-octubre2024.vue'),
        meta: {
            title: "Octubre 2024 | Blog Humanitas",
            requireAuth: false
        },
    },
]